<template>
  <div>
    <div class="prose prose-md md:prose-lg">
      <h1>Cafeteria Plans (125 Plans)</h1>
      <p>
        An employer can set up a Cafeteria Plan to reimburse employees for
        selected medical expenses. These plans are pre-tax benefits for
        employees and may include:
      </p>
      <ul>
        <li>Flexible Spending Accounts</li>
        <li>Dependent Care Assistance Programs</li>
        <li>Adoption Assistance Programs</li>
        <li>Premium Conversion Plans</li>
      </ul>
      <p>
        A cafeteria plan is a program that employers offer to help employees pay
        for certain expenses (like health insurance and dependent care) with
        pre-tax dollars. Employees get to select which benefits they really want
        (like ordering a lunch item à la carte instead of being stuck with the
        full meal combo—hence the name “cafeteria”). Employers like cafeteria
        plans because, in addition to having happier employees, they also save
        on taxes.
      </p>
    </div>

    <InfoDropdown title="Important features of this plan">
      <p>
        There may also be state income tax savings. Salary reductions lower
        earned income, which can impact the earned income credit for eligible
        taxpayers (though this is not the case in our example). In many cases,
        that can result in even greater tax savings.
      </p>
      <p>
        The amount other employees will save depends on which family members are
        covered, the premiums for the coverage, the total family income, and the
        tax deductions and exemptions claimed.
      </p>
      <p>
        It is important to know before signing the election forms that employees
        will not be able to change their benefit elections (i.e., the choices
        about which benefits to pay for through the cafeteria plan) or salary
        reduction amounts except at open enrollment and on the occurrence of
        certain events such as marriage or divorce. This rule is called the
        “irrevocable election requirement.”
      </p>
      <p>Cafeteria Plans are regulated by IRS Section 125.</p>
    </InfoDropdown>

    <InfoDropdown
      title="How does this work in real life?"
      v-bind:visible="showExample"
    >
      <p>
        Take the example of Widgets, Inc. John DoGood is the president of
        Widgets, Inc., a company with 70 employees. One of his new hires, Jane,
        just came from MegaCo., which has thousands of employees and sponsors a
        cafeteria plan. She has been asking Mr. DoGood to set up a cafeteria
        plan for Widgets, Inc., but to be honest, Mr. DoGood does not really
        know what a cafeteria plan is.
      </p>
      <p>
        <em
          >“What good would this be? Sure, it might make sense for a big company
          like MegaCo., but Widgets, Inc. is just not in that league. Besides,
          Widgets, Inc. already provides a solid health insurance plan!”
        </em>
      </p>
      <p>
        Widgets, Inc. currently pays for 80% of employee-only premiums, and
        employees pay the remaining 20%. Employees with families pay 100% of the
        cost of coverage for their spouses and children. The employees’ share of
        the cost is deducted from their pay on an after-tax basis.
      </p>
      <p>
        Could a cafeteria plan work for Widgets, Inc.? Yes! Its employees could
        easily benefit from a premium-only plan (POP). A POP is the simplest
        type of cafeteria plan. It is designed for one purpose: to help
        employees save money by letting them pay for their share of insurance
        premiums with pre-tax dollars.
      </p>
      <h3>Premium-Only Plan (POP) Description</h3>
      <p>
        Mr. DoGood understands now: a POP really makes sense for a business like
        his. Employees are already making after-tax payroll deductions to pay
        for their share of insurance costs. Just setting up the POP and telling
        payroll to take those same deductions on a pre-tax basis means that
        everyone gets to save money.
      </p>
      <p>
        A cafeteria plan helps employers in other ways, as well. In addition to
        enabling the employer to save on its share of FICA (Social Security and
        Medicare) and FUTA (federal unemployment) taxes, a cafeteria plan can:
      </p>
      <ul>
        <li>help recruit employees and keep them satisfied</li>
        <li>
          increase flexibility to design employee benefits for diverse employee
          needs
        </li>
        <li>
          in some states, save on state unemployment insurance and workers’
          compensation taxes
        </li>
        <li>cushion the blow of huge premium increases</li>
        <li>increase employee awareness of the cost of each benefit</li>
      </ul>
      <p>
        <em>“I like it,”</em> says Mr. DoGood, <em>“but what’s the catch?”</em>
      </p>
      <p>
        Well, the IRS does not give out tax breaks without getting some
        assurances that they’re being used properly. Widgets, Inc. will have to
        meet some legal requirements, but this is easy so long as Mr. DoGood
        gets good legal advice in setting up and operating his plan.
      </p>
      <p>
        <em>“That’s what I was worried about,”</em> says Mr. DoGood.
        <em
          >“Attorneys! I’m not MegaCo., you know, I have a small budget and I
          can’t afford more administrative staff right now.”</em
        >
      </p>
      <p>
        No worries. The POP has minimal set-up costs. Just tell the payroll
        folks what to do and how to explain the plan to employees. Someone also
        needs to handle the distribution and collection of election forms. A
        third-party administrator, like Rocky Mountain Reserve, can help with
        these tasks and Widgets, Inc. will still save money!
      </p>
    </InfoDropdown>

    <InfoDropdown
      title="How much will his employees really save on taxes?"
      v-bind:visible="showExample"
      class="cost-example"
    >
      <p>
        Take Jane as an example. She is married and has one child. The company
        pays for 80% of Jane’s own health insurance but nothing for her family.
        She pays $6,400 in premiums ($700 for her share of the employee-only
        premium, plus $5,700 for family coverage) under Widgets, Inc.’s health
        insurance plan. In 2020, Jane earns $75,000 and her husband (a student)
        earns no income. They file a joint tax return.
      </p>
      <table class="w-full mx-0 my-4 text-center border-spacing-0">
        <tr>
          <th class="p-2 text-white rounded-tl bg-neutral-900">
            POP Cafeteria Plan
          </th>
          <th class="p-2 text-white rounded-tr bg-neutral-900">
            No Cafeteria Plan
          </th>
        </tr>
        <tr>
          <td class="p-2 border-0 border-l border-solid border-neutral">
            $75,000
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            $75,000
          </td>
        </tr>
        <tr class="p-2 pt-0 text-sm text-left">
          <td
            class="p-2 pt-0 border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            1. Adjusted Gross Income
          </td>
        </tr>
        <tr class="bg-neutral-light">
          <td class="p-2 border-0 border-l border-solid border-neutral">
            ($6,400)
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">$0</td>
        </tr>
        <tr class="p-2 pt-0 text-sm text-left bg-neutral-light">
          <td
            class="p-2 pt-0 border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            2. Cafeteria Plan Salary Reductions
          </td>
        </tr>
        <tr>
          <td class="p-2 border-0 border-l border-solid border-neutral">
            $68,600
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            $75,000
          </td>
        </tr>
        <tr class="p-2 pt-0 text-sm text-left">
          <td
            class="p-2 pt-0 border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            3. W-2 Gross Wages
          </td>
        </tr>
        <tr class="bg-neutral-light">
          <td class="p-2 border-0 border-l border-solid border-neutral">
            ($11,400)
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            ($11,400)
          </td>
        </tr>
        <tr class="p-2 pt-0 text-sm text-left bg-neutral-light">
          <td
            class="p-2 pt-0 border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            4. Standard Deduction
          </td>
        </tr>
        <tr>
          <td class="p-2 border-0 border-l border-solid border-neutral">
            ($10,950)
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            ($10,950)
          </td>
        </tr>
        <tr class="p-2 pt-0 text-sm text-left">
          <td
            class="p-2 pt-0 border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            5. Exemptions
          </td>
        </tr>
        <tr class="bg-neutral-light">
          <td class="p-2 border-0 border-l border-solid border-neutral">
            $46,250
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            $52,650
          </td>
        </tr>
        <tr class="p-2 pt-0 text-sm text-left bg-neutral-light">
          <td
            class="p-2 pt-0 border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            6. Taxable Income (lines 3, 4, 5)
          </td>
        </tr>
        <tr>
          <td class="p-2 border-0 border-l border-solid border-neutral">
            $68,600
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            $75,000
          </td>
        </tr>
        <tr class="p-2 pt-0 text-sm text-left">
          <td
            class="p-2 pt-0 border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            7. W-2 Gross Wages
          </td>
        </tr>
        <tr class="bg-neutral-light">
          <td class="p-2 border-0 border-l border-solid border-neutral">
            ($6,100)
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            ($7,060)
          </td>
        </tr>
        <tr class="p-2 pt-0 text-sm text-left bg-neutral-light">
          <td
            class="p-2 pt-0 border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            8. Federal Income Tax (line 6 @ tax schedule)
          </td>
        </tr>
        <tr>
          <td class="p-2 border-0 border-l border-solid border-neutral">
            ($5,248)
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            ($5,738)
          </td>
        </tr>
        <tr class="p-2 pt-0 text-sm text-left">
          <td
            class="p-2 pt-0 border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            9. FICA Tax (7.65% of line 3)
          </td>
        </tr>
        <tr class="bg-neutral-light">
          <td class="p-2 border-0 border-l border-solid border-neutral">$0</td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            ($6,400)
          </td>
        </tr>
        <tr class="p-2 pt-0 text-sm text-left bg-neutral-light">
          <td
            class="p-2 pt-0 border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            10. After-Tax Premium Payments
          </td>
        </tr>
        <tr>
          <td class="p-2 border-0 border-l border-solid border-neutral">
            $57,252
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            $55,802
          </td>
        </tr>
        <tr class="p-2 pt-0 text-sm text-left">
          <td
            class="p-2 pt-0 border-0 border-b border-l border-r border-solid rounded-b border-neutral"
            colspan="2"
          >
            11. Pay After Taxes and Premium Payments (lines 7, 8, 9, 10)
          </td>
        </tr>
      </table>

      <p>
        Jane would save about $1,450 in taxes (line 11, col. 1 minus line 11,
        col. 2) by paying for her health insurance premiums under the POP. A
        shortcut way to determine Jane’s savings is to multiply 22.65% by her
        $6,400 of salary reductions (22.65% = her 15% marginal tax rate plus
        7.65% for FICA).
      </p>

      <p>
        Widgets, Inc. also saves on taxes. For example, it saves $490 in FICA
        employment taxes (i.e., 7.65% × $6,400 of salary reductions).
      </p>

      <p>
        No wonder Jane wants a cafeteria plan—it results in significant tax
        savings. There are some other bonuses as well:
      </p>

      <ul>
        <li>
          employees pay only for the benefits they really want (i.e., they can
          design their benefits “à la carte”)
        </li>
        <li>
          often, more benefits are offered than might otherwise be available
          without a cafeteria plan
        </li>
      </ul>
    </InfoDropdown>
  </div>
</template>

<script>
import InfoDropdown from "@/components/ui/InfoDropdown.vue";

export default {
  components: {
    InfoDropdown: InfoDropdown
  },
  props: {
    showExample: Boolean
  }
};
</script>
