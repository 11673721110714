<template>
  <div
    class="mx-0 my-4 border rounded cursor-pointer divide-solid divide-neutral"
  >
    <div
      class="flex flex-row p-4 font-semibold align-middle border-b select-none"
      @click="showText = !showText"
    >
      <span class="flex-1">{{ title }}</span> <span :class="icon" />
    </div>
    <transition @enter="enter" @after-enter="afterEnter" @leave="leave">
      <div
        class="max-w-full px-4 py-0 overflow-hidden prose origin-top text"
        v-show="showText"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showText: this.visible
    };
  },
  props: {
    title: String,
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon: function() {
      if (this.showText) {
        return "flaticon-minus";
      } else {
        return "flaticon-plus";
      }
    }
  },
  methods: {
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      var height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      //Calculate transition time based on height
      let duration = (Math.floor(parseInt(height) / 100) + 1) * 0.2;

      // Trigger the animation.
      // We use `setTimeout` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      setTimeout(() => {
        element.style.transition = `height ${duration}s ease-in-out`;
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
      // element.style.padding = "1rem 1rem";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      //Calculate transition time based on height
      let duration = Math.floor(parseInt(height) / 100) * 0.15;

      setTimeout(() => {
        element.style.transition = `height ${duration}s ease-in-out`;
        element.style.height = 0;
      });
    }
  }
};
</script>

<style scoped>
.text > :first-child {
  margin-top: 1rem;
}

.text > :last-child {
  margin-bottom: 1rem;
}
</style>
